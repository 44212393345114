import constants from "../../../../components/entity/constants";
import {createAsyncSelectFormField, createField} from "../../../../components/helpers/fieldHelper";
import {areasFetcher, DEFAULT_LIMIT} from "../../../../lib/form-options-fetchers";

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "name",
        t("zones.fields.name"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "area_id",
        t("zones.fields.area"),
        (params) => areasFetcher(cache, apiClient, t, {
            ...params,
            code: "AR",
            limit: DEFAULT_LIMIT,
            sort: "name"
        },true),
        false
    ),
])