import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useNavigate } from "react-router-dom"
import { getTableFilter } from "./LodgingsCollection.filter"
import { getActions, getTableActions} from "./LodgingCollection.actions"
import {getTableColumns} from "./LodgingsCollection.columns"
import {getTableExport} from "./LodgingsCollection.export"
import {useCache} from "../../../../context/cache.context"


const LodgingsCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        actions: getTableActions(apiClient, t, volcanoAuth.user),
        fetcher: (params) => {
            const queryFilter = {
                ...params,
                sort: "name"
            }
            return apiClient.activityManager.lodging.getLodgings(queryFilter)
        },
        onExport: getTableExport(apiClient, t)
    };

    return (
        <CollectionPage
            title={t("lodgings.title")}
            subtitle={t("lodgings.subtitle")}
            actions={getActions(apiClient, t, navigate, volcanoAuth.user)}
            filterConfig={getTableFilter(t, apiClient, volcanoAuth.user, cache)}
            table={table}
        />
    )
}

export default LodgingsCollectionPage;
