import { useTranslation } from "react-i18next";
import constants from "../../../../../../components/entity/constants";
import {
    createField, createLinkField
} from "../../../../../../components/helpers/fieldHelper";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";

const LodgingPickupPoints = (props) => {

    const { t } = useTranslation("vbms")


    const columns = [
        createLinkField(
            createField(
                "point.name",
                t("pickup_points.fields.name"),
                constants.STRING_TYPE
            ),
            {
                route: "pickup_points",
                field: "id",
            }
        ),
        createField(
            "point.area.name",
            t("pickup_points.fields.area"),
            constants.STRING_TYPE
        ),
        createField(
            "point.zone.name",
            t("pickup_points.fields.zone"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("pickup_points.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "modified",
            t("pickup_points.fields.modified"),
            constants.DATETIME_TYPE
        ),
    ]



    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )

}

export default LodgingPickupPoints