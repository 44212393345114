import userPermissions from "../../../lib/user-permissions";
import ZonesCollectionPage from "../Zones/ZonesCollection/ZonesCollection";
import ZonePage from "../Zones/ZonePage/ZonePage";
import PickupPointsCollectionPage from "../PickupPoints/PickupPointsCollection/PickupPointsCollection";
import PickupPointPage from "../PickupPoints/PickupPointPage/PickupPointPage";
import LodgingsCollectionPage from "../Lodgings/LodgingsCollection/LodgingsCollection";
import LodgingPage from "../Lodgings/LodgingPage/LodgingPage";


const activitiesRoutes = {
    zones: {
        path: "/activity-manager/config/zones",
        element: ZonesCollectionPage,
        permissions: [
            userPermissions.PERM_ZONES_VIEW,
            userPermissions.PERM_ZONES_VIEW_OWN
        ]
    },
    zones_view: {
        path: "/activity-manager/config/zones/:entityId",
        element: ZonePage,
        permissions: [
            userPermissions.PERM_ZONES_VIEW,
            userPermissions.PERM_ZONES_VIEW_OWN
        ]
    },
    pickup_points: {
        path: "/activity-manager/config/pickup-points",
        element: PickupPointsCollectionPage,
        permissions: [
            userPermissions.PERM_PICKUP_POINTS_VIEW,
            userPermissions.PERM_PICKUP_POINTS_VIEW_OWN
        ]
    },
    pickup_points_view: {
        path: "/activity-manager/config/pickup-points/:entityId",
        element: PickupPointPage,
        permissions: [
            userPermissions.PERM_PICKUP_POINTS_VIEW,
            userPermissions.PERM_PICKUP_POINTS_VIEW_OWN
        ]
    },
    lodgings: {
        path: "/activity-manager/config/lodgings",
        element: LodgingsCollectionPage,
        permissions: [
            userPermissions.PERM_LODGINGS_VIEW,
            userPermissions.PERM_LODGINGS_VIEW_OWN
        ]
    },
    lodgings_view: {
        path: "/activity-manager/config/lodgings/:entityId",
        element: LodgingPage,
        permissions: [
            userPermissions.PERM_LODGINGS_VIEW,
            userPermissions.PERM_LODGINGS_VIEW_OWN
        ]
    }
}

export default activitiesRoutes
