import constants from "../../../../components/entity/constants";
import { createField, createLinkField } from "../../../../components/helpers/fieldHelper";

export const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "point.name",
            t("lodgings.fields.name"),
            constants.STRING_TYPE
        ),
        {
            route: "lodgings",
            field: "id",
        }
    ),
    createField(
        "point.zone.name",
        t("lodgings.fields.zone"),
        constants.STRING_TYPE
    ),
    createField(
        "point.area.name",
        t("lodgings.fields.locality"),
        constants.STRING_TYPE
    ),
    createField(
        "created",
        t("lodgings.fields.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "modified",
        t("lodgings.fields.modified"),
        constants.DATETIME_TYPE
    ),
])